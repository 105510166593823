body {
  background: #fafafa;
  color: #333;
}

.MuiAppBar-root {
  background-color: #9ba03b; /* Pet Fresh main color */
}

.MuiTab-root {
  color: #fff;
}

.MuiFormControl-root MuiFormControl-fullWidth {
  background: white;
}

.MuiInputBase-formControl {
  background: white;
}

.MuiContainer-root.MuiContainer-maxWidthLg.css-1qsxih2 {
  min-height: 100vh;
}

.placedBy, .customerName {
  text-transform: capitalize
}

#root {
  background: rgb(250, 250, 250);
}

.css-1qsxih2 {
  max-width: 100% !important;
}

.table-key {
  background: #D9D9D9;
  font-weight: bold;
  padding-left: 1em;
}

.confirm-blk-table-row > td {
  border: 0.5px solid black;
}

.confirm-blk-table-key {
  background: #D9D9D9;
  font-weight: bold;
  padding-left: 1em;
  border: 0.5px solid black;
}